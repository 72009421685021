import React from 'react'
import Layout from '../components/layout'
import SoftwareDeveloper from '../components/categories/software-developer'
import Seo from '../components/seo'

const SoftwareDeveloperPage = ({ location }) => {
  return (
    <Layout location={location}>
      <SoftwareDeveloper />
    </Layout>
  )
}

export const Head = ({ location }) => {
  const title = 'Top Software Developer Freelancers | Codestaff'
  const description =
    'Hire the best Software Developer freelancers at Codestaff. Get the top 1% of Software Developer professionals to join your team.'

  return <Seo type="page" title={title} description={description} />
}

export default SoftwareDeveloperPage
