import React from 'react';
import { Link } from 'gatsby';
import CxSearch from './cx-search'
import TopTrendingSkills from '../top-trending-skills';
import HireAndApply from '../hire-and-apply';
import Helmet from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components'

const MainWrapper = styled.div`
margin: 0;
`

const faqMeta1 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "Should I Create A Mobile Or A Web App?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "Both web and mobile apps have benefits and flaws. Mobile apps are more expensive to build than web apps but can provide you with more data collecting and monetization capabilities than a web application. However, you may need both mobile and web apps to scale your business."
        }
    }
}

const faqMeta2 = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": {
        "@type": "Question",
        "name": "How Do You Handle Communications?",
        "acceptedAnswer": {
            "@type": "Answer",
            "text": "We use telephone calls, email, Skype and can also schedule a meeting. You can choose a means of communication that is convenient for you."
        }
    }
}

const SoftwareDeveloper = () => (
    <MainWrapper>
        <main>
            <Helmet>
                <script type="application/ld+json">{JSON.stringify(faqMeta1)}</script>
                <script type="application/ld+json">{JSON.stringify(faqMeta2)}</script>
            </Helmet>
            <section className="cx_catagories">
                <div className="container-fluid cx_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-sm-10">
                                <h2>
                                    Hire top freelance<br /><span> Software Developers.</span>
                                </h2>
                                <p>
                                    Codestaff is a marketplace for top Software Developers. Top
                                    companies and start-ups choose Codestaff professional Software
                                    Developers for their mission critical software projects.
                            </p>
                                <Link to="/hire/" className="skills-banner-btn">
                                    Hire a top Software Developer now
                                <StaticImage src='../../images/arrow.png' alt='arrow' className="img-fluid" style={{ margin: '0 1rem' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <CxSearch title='HIRE FREELANCE SOFTWARE DEVELOPERS' banner='/software-developer.png' bannerAlt='software developer' />
            </section>
            <HireAndApply />
            <TopTrendingSkills />
        </main>
    </MainWrapper>)

export default SoftwareDeveloper